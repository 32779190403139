<template>
	<div>
		<div v-if="step === 'SEARCH_WINERY'">
			<b-row>
        <b-col>
					<h5>{{ $t('winelist.addWineModal.searchWinery') }}</h5>
					<div class="spacer m"></div>
				</b-col>
			</b-row>
			<SearchWinery ref="searchWinery" @foundWineries="handleFoundWineries" @hasTypedSearchText="handleHasTypedSearchText" />
		</div>
		
		<div v-if="step === 'CHOOSE_WINERY'">
			<ChooseWineryFilter />
			<div class="spacer m"></div>
			<ChooseWinery :wineries="wineries" @winerySelected="handleWinerySelected" />
		</div>
		
		<b-alert :variant="alertType" :show="alertIsVisible">
			{{ alertMessage }}
		</b-alert>

		<div class="step-footer">
			<b-button v-if="cancelButtonVisible" class="cancel" @click="cancel()">
				{{ $t('general.buttons.cancel') }}
			</b-button>
			<b-button v-if="backButtonVisible" class="back" @click="backClick()">
				{{ $t('general.buttons.back') }}
			</b-button>
			<b-button :disabled="okBtnDisabled" variant="success" @click="okClick()">
				{{ this.$t('winelist.addWineModal.chooseWinery') }}
			</b-button>
		</div>
	</div>
</template>

<script>

import SearchWinery from '@/components/modals/addWine/step1/SearchWinery.vue'
import ChooseWinery from '@/components/modals/addWine/step1/ChooseWinery.vue'
import ChooseWineryFilter from '@/components/modals/addWine/step1/ChooseWineryFilter.vue'

const STEP_SEARCH_WINERY = 'SEARCH_WINERY'
const STEP_CHOOSE_WINERY = 'CHOOSE_WINERY'

export default {
	name: 'Step1',
	mounted() {
    this.$root.$on('showCreateWinesStep1Alert', (alert) => {
      this.handleShowAlert(alert)
    })
		this.$root.$on('hideCreateWinesStep1Alert', () => {
			this.handleHideAlert()
		})
  },
	data() {
		return {
			wineries: [],
			backButtonVisible: false,
			cancelButtonVisible: true,
			wineriesPresent: false,
			okBtnDisabled: true,
			selectedWinery: {},
			step: STEP_SEARCH_WINERY,
			alertType: 'success',
			alertIsVisible: false,
			alertMessage: ''
		}
	},
	components: {
		SearchWinery,
		ChooseWinery,
		ChooseWineryFilter,
	},
	methods: {
		handleFoundWineries(wineries) {
			this.wineries = wineries
			this.okBtnDisabled = true
			this.backButtonVisible = true
			this.cancelButtonVisible = false
			this.step = STEP_CHOOSE_WINERY
		},
		handleWinerySelected(selectedWinery) {
			this.selectedWinery = selectedWinery
			this.okBtnDisabled = this.noWinerySelected()
		},
		handleHasTypedSearchText(hasTyped) {
			this.okBtnDisabled = !hasTyped
		},
		handleShowAlert(alert) {
			this.alertMessage = alert.message
			this.alertType = alert.type
			this.alertIsVisible = true
		},
		handleHideAlert() {
			this.resetAlert()
		},
		resetAlert() {
			this.alertMessage = ''
			this.alertType = 'success'
			this.alertIsVisible = false
		},
		noWinerySelected() {
			return Object.keys(this.selectedWinery).length === 0
		},
		backClick() {
			this.resetAlert()
			this.okBtnDisabled = true
			this.selectedWinery = {}
			this.wineries = []
			this.step = STEP_SEARCH_WINERY
			this.backButtonVisible = false
			this.cancelButtonVisible = true
		},
		/**
		 * Start loading wineries from search text or
		 * send event to CreateWinesModal when winery is selected
		 */
		okClick() {
			this.resetAlert()
			if (this.step === STEP_SEARCH_WINERY) {
				this.$refs.searchWinery.loadWineries()
			} else if (this.step === STEP_CHOOSE_WINERY) {
				this.$emit('okFromStep1', this.selectedWinery)
			}
		},
		cancel() {
			this.resetAlert()
			this.$emit('cancel')
		},
	},
	computed: {
		winerySelected() {
			return Object.keys(this.selectedWinery).length > 0
		}
	}
}

</script>