<template>
  <div>
    <b-dropdown size="lg" toggle-class="text-decoration-none" class="m-2 dot-menu" no-caret>
      <template #button-content>
        <b-icon icon="three-dots-vertical"></b-icon>
      </template>
      <b-dropdown-item-button @click="renameWine">{{ $t('general.buttons.customName') }}</b-dropdown-item-button>
      <b-dropdown-item-button @click="duplicateWine">{{ $t('general.buttons.duplicate') }}</b-dropdown-item-button>
      <b-dropdown-item-button @click="deleteWine">{{ $t('general.buttons.delete') }}</b-dropdown-item-button>

    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'Dotmenu',
  data() {
    return {
    }
  },
  methods: {
    renameWine() {
      this.$emit('renameWine')
    },
    duplicateWine() {
      this.$emit('duplicateWine')
    },
    deleteWine() {
      this.$emit('deleteWine')
    },
  },
}
</script>
<style scoped>

</style>