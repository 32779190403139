<template>
  <div>
    <b-row align-v="center">
			<b-col>
				<h5>{{ $t('winelist.addWineModal.searchWine') }}</h5>
			</b-col>
      <b-col cols="4">
        <FrontendFilterSearch :placeholder="$t('general.filter')" />
       
      </b-col>
    </b-row>
  </div>


</template>

<script>
import FrontendFilterSearch from '../../../inputs/FrontendFilterSearch.vue';



export default {
  name: 'ChooseWineSearch',
  data() {
    return {
    }
  },
  components: {
    FrontendFilterSearch
  },
  methods: {
  },
  computed: {
  },
}

</script>