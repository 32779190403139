<template>
	<div class="add-wine-row">
		<div >
			<b-row>
				<b-col cols="12" class="text-center">
					<LoadingAnimation v-if="loading" />
				</b-col>
			</b-row>
		</div>
		
		<div v-if="loading == false">
			<button v-if="inputIsHidden" @click="toggleInput()" class="iconButton add">{{ $t('winelist.addWineModal.addWineRow') }}</button>
			<b-row v-else>
    <b-col class="col-px-width-24">
        <button @click="toggleInput()" class="cancel-button inline-row-button"></button>
    </b-col>
    <b-col style="flex-grow: 2">
        <b-form-input ref="wineName" v-model="wineName" :placeholder="$t('winelist.addWineModal.wineNamePlaceholder')"></b-form-input>
    </b-col>
    <b-col>
        <SearchDataList ref="wineTypeSearch" :autoSuggestComplete="wineTypeOptions" :isLastSearch="false" :itemClickedFunction="handleWineTypeSelected.bind(selectedWineType)" :placeholder="$t('winelist.addWineModal.colorPlaceholder')"/>
    </b-col>
    <b-col>
        <SearchDataList ref="grapeVarietySearch" :disabled="checkIfWineTypeIsSelected" :autoSuggestComplete="grapeVarietyOptions" :isLastSearch="false" :itemClickedFunction="handleGrapeVarietySelected.bind(selectedGrapeVariety)" :placeholder="$t('winelist.addWineModal.grapeVarietyPlaceholder')"/>
    </b-col>
    <b-col class="col-px-width-24">
        <button @click="addWine()" class="inlineRow accept" :disabled="checkIfWineTypeIsSelected || checkIfGrapeVarietyIsSelected"></button>
    </b-col>
</b-row>
		</div>
	</div>
</template>

<script>
import DataService from '@/services/StrapiService'
import DataMappingService from '@/services/DataMappingService'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import Wine from '@/model/Wine.js'
import SearchDataList from '@/components/SearchDataList.vue'

export default {
	name: 'AddWineRow',
	components: {
		LoadingAnimation,
		SearchDataList
	},
	props: {
    selectedWinery: {
      type: Object,
      required: true
    }
  },
	emits: ['wineAdded'],
	data() {
		return {
			loading: false,
			inputIsHidden: true,
			wineTypeOptions: [],
			wineName: '',
      selectedWineType: {
				text: this.$t('general.dropdownText'),
				value: null
			},
      selectedGrapeVariety: {
				text: this.$t('general.dropdownText'),
				value: null
			}
		}
	},
	mounted() {
		this.getWineTypeOptions()
	},
	methods: {
		getWineTypeOptions() {
			this.showLoading()
			DataService.getAllWineTypes( 'title' ).then((response) => {
				this.hideLoading()
				this.wineTypeOptions = DataMappingService.mapGetAllWineTypesResponseToOptions(response)
			}).catch(() => {
					this.hideLoading()
					this.showErrorAlert(this.$t('errors.loadingError'))
			})
		},
		handleWineTypeSelected( wineTypeOption ) {
			if (typeof wineTypeOption === 'object' && wineTypeOption !== null) {
				this.selectedWineType = wineTypeOption
			} else {
				if (this.wineTypeOptions.some(option => option.text === wineTypeOption)) {
					this.selectedWineType = this.wineTypeOptions.find(option => option.text === wineTypeOption)
				} else {
					this.resetSelectedWineType()
				}
			}
		},
		handleGrapeVarietySelected(selectedGrapeVariety) {
			if (typeof selectedGrapeVariety === 'object' && selectedGrapeVariety !== null) {
				this.selectedGrapeVariety = selectedGrapeVariety
			} else {

				if (this.selectedWineType.grapeVarieties.some(option => option.title === selectedGrapeVariety)) {
					var foundWineType = this.selectedWineType.grapeVarieties.find(option => option.title === selectedGrapeVariety)
					this.selectedGrapeVariety.value = foundWineType.id
					this.selectedGrapeVariety.text = foundWineType.title
				} else {
					this.resetSelectedGrapeVariety()
				}

			}
		},
		async addWine() {
			const wine 				= new Wine()
			wine.title 				= this.wineName.trim()
			wine.region 			= { id: this.selectedWinery.region.id }
			wine.land 				= { id: this.selectedWinery.land.id }
			wine.grapeVariety = { id: this.selectedGrapeVariety.value }
			wine.wineType 		= { id: this.selectedWineType.value }
			wine.winery 			= { id: this.selectedWinery.id }
			wine.classification = null

			if (this.validateWine(wine) === false) {
				this.showErrorAlert(this.$t('errors.savingError'))
				return
			}

			const canCreateNewWine = await this.checkDuplicateWine(wine)
			if (canCreateNewWine === true) {
				this.sendCreateWineRequest(wine)
			} else {
				this.showErrorAlert(this.$t('winelist.addWineModal.wineAlreadyExists'))
			}
		},
		validateWine(wine) {
			return wine.title.length > 0 && wine.region.id != null && wine.land.id != null && wine.grapeVariety.id != null && wine.wineType.id != null && wine.winery.id != null
		},
		/**
		 * Check if wine already exists in db
		 * returns true if new wine can be created
		 * @param {*} wine 
		 */
		async checkDuplicateWine(wine) {
			this.showLoading()
			const response = await DataService.findWines(wine)
			const wines = DataMappingService.mapFindWinesResponse(response)
			const foundGeneralWine = wines.find(w => w.venue.id === null) !== undefined
			
			this.hideLoading()
			if (wines.length > 0 && foundGeneralWine) {
				return false
			} else {
				return true
			}
		},
		async sendCreateWineRequest(wine) {
			this.showLoading()
			await DataService.postWine(wine)
				.then((response) => {
					this.hideLoading()
					const wineResponse = DataMappingService.mapPostWineResponse(response)
					this.$emit('wineAdded', wineResponse)
					this.hideInput()
					this.resetUI()
					this.showSuccessAlert(this.$t('winelist.addWineModal.addedWines'))
				})
				.catch(() => {
					this.showErrorAlert(this.$t('errors.savingError'))
					this.hideLoading()
				})
		},
		showLoading() {
			this.loading = true
		},
		hideLoading() {
			this.loading = false
		},
		toggleInput() {
			this.inputIsHidden = !this.inputIsHidden
			this.resetUI()
		},
		hideInput() {
			this.inputIsHidden = true
		},
	
		resetUI() {
			this.wineName = ''
			this.resetSelectedWineType()
			this.resetSelectedGrapeVariety()
			this.resetAlert()
		},
		resetSelectedGrapeVariety() {
			this.selectedGrapeVariety = {
				text: this.$t('general.dropdownText'),
				value: null
			}
			if (this.$refs.grapeVarietySearch != null) {
				this.$refs.grapeVarietySearch.deleteSearch()
			}
		},
		resetSelectedWineType() {
			this.selectedWineType = {
				text: this.$t('general.dropdownText'),
				value: null
			}
			if (this.$refs.wineTypeSearch != null) {
				this.$refs.wineTypeSearch.deleteSearch()
			}
		},
		showErrorAlert(msg) {
			this.$root.$emit('showCreateWinesStep2Alert', { message: msg, type: 'warning'})
		},
		showSuccessAlert(msg) {
			this.$root.$emit('showCreateWinesStep2Alert', { message: msg, type: 'success' })
		},
		resetAlert() {
			this.$root.$emit('hideCreateWinesStep2Alert')
		}
	},
	watch: {
		selectedWineType: function (newVal, oldVal) {
			if (newVal.value !== oldVal.value) {
				this.resetSelectedGrapeVariety()
			}
		}
	},
	computed: {
		grapeVarietyOptions() {
				if (this.selectedWineType != null && this.selectedWineType.grapeVarieties != null) {
					const wineryGrapeVarietyIds = this.selectedWinery.land.grapeVarieties.map(grapeVariety => {
						return grapeVariety.id
					})
					const filteredGrapeVarieties = this.selectedWineType.grapeVarieties.filter(grapeVariety => {
						return wineryGrapeVarietyIds.includes(grapeVariety.id)
					})
					const options = filteredGrapeVarieties.map(grapeVariety => {
						return { text: grapeVariety.title, value: grapeVariety.id }
					})
						options.sort((a, b) => a.text.localeCompare(b.text))
						return options
				} else {
						return []
				}
		},
    checkIfWineTypeIsSelected() {
			return this.selectedWineType.value === undefined || this.selectedWineType.value === null || this.selectedWineType.value === ''
		},
		checkIfGrapeVarietyIsSelected() {
			return this.selectedGrapeVariety.value === undefined || this.selectedGrapeVariety.value === null || this.selectedGrapeVariety.value === ''
		},
	}
}

</script>
<style scoped>
</style>