<template>
  <div class="complement-wines">
    
		<div class="complement-wines-title">
      <h2 class="d-inline">{{ $t('winelist.addWineModal.step3') }}</h2>
      <span class="winery-name d-inline ml-1">{{ selectedWinery.title }}</span>
    </div>
		<div class="complement-wines-list">
			<b-row class="wine-row" v-for="(wine, index) in selectedWineList" :key="index">
				<div class="wine-head d-inline mb-2">
					<span class="wine-title"> {{ wine.title }}</span>
					<div class="d-inline wine-info">
						<span>{{ wine.wineType.title }}, </span>
						<span>{{ wine.grapeVariety.title }}</span>
					</div>
				</div>

				<b-row v-if="!wine.renameMode" class="row-wrapper-winedetails">
					<b-row class="row-wine-details">
						<div class="dropDown year w-2">
							<h3>{{ $t('general.year') }}</h3>
						</div>
						<div class="dropDown w-2">
							<h3>{{ $t('general.size') }}</h3>
						</div>
						<div class="spacer w-1"></div>

						<div class="numberInput w-2">
							<h3>{{ $t('general.amount_new') }}</h3>
						</div>
						<div style="flex-grow: 2" class="dataSearchField w-3">
							<h3>{{ $t('general.supplier') }}</h3>
						</div>
						<div class="shelf w-2">
							<h3>{{ $t('general.shelf') }}</h3>
						</div>
						<div class="w-1 spacer"></div>
						<div class="numberInput w-2">
							<h3>{{ $t('general.ekNet') }} {{ getCurrency }}</h3>
						</div>
						<div class="numberInput w-2">
							<h3>{{ $t('general.vkGross') }} {{ getCurrency }}</h3>
						</div>
						<div class="numberInput w-2">
							<h3>{{ $t('general.weq') }}</h3>
						</div>
						<div class="w-1 spacer"></div>
						<div class="menu"></div>
					</b-row>

					<b-row>
						<div class="dropDown year w-2">
							<b-dropdown :text="yearDropdownText(wine.selectedYear)" class="yearsDropdown" :class="{ 'invalid': wine.validation.showYearError }">
								<b-dropdown-item v-for="year in yearsOptions" :key="year.value" :value="year.value"
									@click="setYear(wine, year, index)">
									{{ year.text }}
								</b-dropdown-item>
							</b-dropdown>
						</div>

						<div class="dropDown w-2">
							<b-dropdown :text="selectedBottlesizeText(wine.selectedBottlesize)" class="yearsDropdown" :class="{ 'invalid': wine.validation.showBottleSizeError }">
								<b-dropdown-item v-for="size in getBottleSizes" :key="size.id" :value="size.attributes.amount"
									@click="setBottleSize(wine, size, index)">
									{{ size.attributes.amount }} {{ $t('general.liter_short') }}
								</b-dropdown-item>
							</b-dropdown>
						</div>

						<div class="spacer w-1"></div>

						<div class="numberInput w-2">
							<b-form-input ref="wineAmount" v-model="wine.wineAmountFloat" @change="updateValues(index)" @focus="$event.target.select()" min="0"
								type="number" :class="{ 'invalid': wine.validation.showWineAmountError }">
							</b-form-input>
						</div>

						<div style="flex-grow: 2" class="dataSearchField w-3">
							<SearchDataList :isLastSearch="false" :searchFromOutside="wineSupplierText(wine.selectedWineSupplier.id)"
								:autoSuggestComplete="venueSupplierOptions"
								:itemClickedFunction="setValueFromWineSupplierSuggestField.bind(this, wine)" :supplier="true" 
								:class="{ 'invalid': wine.validation.showWineSupplierError }" />
						</div>

						<div class="shelf w-2">
							<b-form-input @change="updateValues(index)" ref="shelf" v-model="wine.shelf"
								:placeholder="$t('general.shelf')"></b-form-input>
						</div>

						<div class="w-1 spacer"></div>

						<div class="numberInput w-2">
							<b-form-input ref="wineEk" v-model="wine.ek" @change="updateValues(index)" min="0"
								type="number" :class="{ 'invalid': wine.validation.showEkError }"></b-form-input>
						</div>

						<div class="numberInput w-2">
							<b-form-input ref="wineVk" v-model="wine.vk" @change="updateValues(index)" min="0" type="number" 
								:class="{ 'invalid': wine.validation.showVkError }"></b-form-input>
						</div>

						<div class="w-2 weq">
							<Weq :purchasePriceAvg="wine.ek" :sellingPrice="wine.vk" />
						</div>

						<div class="w-1">
							<Dotmenu @deleteWine="handleDelete(wine, index)" @duplicateWine="handleDuplicate(wine)" @renameWine="handleRename(wine, index)" />
						</div>
					</b-row>
				</b-row>

				<div class="rename-wine">
					<RenameWine v-if="wine.renameMode" :currentTitle="wine.title" @renameWine="handleRenameText(wine, index, $event)" @cancelRename="handleRenameCancel(wine, index)" />
				</div>

				<b-alert fade variant="warning" :show="wine.alert.alertIsVisible">
					{{ wine.alert.alertMessage }}
				</b-alert>

			</b-row>

			<b-row v-if="loading">
				<b-col cols="12" class="text-center">
					<LoadingAnimation />
				</b-col>
			</b-row>
	
		</div>
  </div>
</template>

<script>
import DataService from '@/services/StrapiService'
import { mapGetters } from 'vuex'
import SearchDataList from '@/components/SearchDataList.vue'
import * as Utils from '@/utils/functions'
import Weq from '@/components/wineDetail/Weq.vue'
import Dotmenu from '@/components/modals/addWine/step3/Dotmenu.vue'
import RenameWine from '@/components/modals/addWine/step3/RenameWine.vue'
import LoadingAnimation from '@/components/LoadingAnimation.vue'

export default {
  name: 'ComplementWines',
	components: {
    SearchDataList,
    Weq,
    Dotmenu,
    RenameWine,
		LoadingAnimation
  },
	props: {
    selectedWinery: Object,
    selectedWines: Array
  },
  data() {
    return {
      loading: false,
      sortBy: 'title',
      yearsOptions: [],
      wineSearch: '',
      selectedWineList: [],
      venueSupplierOptions: [],
			defaultVenueSupplier: {},
    }
  },
  created() {
		this.loadVenueSupplierOptions()
		this.loadYearsOptions()
    this.initSelectedWines(this.selectedWines)
  },
  methods: {
		loadVenueSupplierOptions() {
			this.venueSupplierOptions = Utils.loadVenueSupplierOptions(this.getVenue)
			const defaultVenueSupplier = Utils.getDefaultVenueSupplierOption(this.getVenue)

			this.defaultVenueSupplier = {
				id: defaultVenueSupplier.value,
				title: defaultVenueSupplier.text,
			}
		},
		loadYearsOptions() {
      this.yearsOptions = []
      this.yearsOptions.push({
        value: 0,
        text: this.$t("general.notDefined")
      })
			var actualYear = new Date().getFullYear();
			for (let i = 0; i < 101; i++) {
				var option = {
					value: actualYear - i,
					text: actualYear - i
				}
				this.yearsOptions.push(option)
			}
		},
    findStandardBottleSize() {
      const standardBottleSize = this.getBottleSizes.find(size => size.attributes.amount === 0.75)
      return standardBottleSize
    },
		initSelectedWines(selectedWines) {
      this.selectedWineList = selectedWines

      this.selectedWineList.forEach(wine => {
				if (!Object.hasOwnProperty.call(wine, 'selectedYear')) {
					wine.selectedYear = -1;
				}
				if (!Object.hasOwnProperty.call(wine, 'selectedBottlesize')) {
					wine.selectedBottlesize = this.findStandardBottleSize();
				}
				if (!Object.hasOwnProperty.call(wine, 'wineAmountFloat')) {
					wine.wineAmountFloat = 0;
				}
				if (!Object.hasOwnProperty.call(wine, 'selectedWineSupplier')) {
					wine.selectedWineSupplier = this.defaultVenueSupplier;
				}
				if (!Object.hasOwnProperty.call(wine, 'shelf')) {
					wine.shelf = '';
				}
				if (!Object.hasOwnProperty.call(wine, 'ek')) {
					wine.ek = 0;
				}
				if (!Object.hasOwnProperty.call(wine, 'vk')) {
					wine.vk = 0;
				}
				if (!Object.hasOwnProperty.call(wine, 'renameMode')) {
					wine.renameMode = false;
				}
				if (!Object.hasOwnProperty.call(wine, 'alert')) {
					wine.alert = {
						alertIsVisible: false,
						alertMessage: ''
					};
				}
				if (!Object.hasOwnProperty.call(wine, 'validation')) {
					wine.validation = {}
				}
      })
    },
		setBottleSize(wine, size, index) {
      wine.selectedBottlesize = { ...size }
			this.updateValues(index)
      this.validate()
    },
    setYear(wine, year, index) {
      wine.selectedYear = year.value
      this.updateValues(index)
			this.validate()
    },
		setValueFromWineSupplierSuggestField(wine, supplier) {
			const index = this.selectedWineList.indexOf(wine);
			this.selectedWineList[index].selectedWineSupplier = {
				title: supplier.text,
				id: supplier.value
			}
			this.updateValues(index)
			this.validate()
    },
		/**
		 * Deletes a wine row from the list. Only possible if the wine has no linked duplicates
		 * @param {*} wine 
		 * @param {*} index 
		 */
    handleDelete(wine, index) {
			if (this.wineIsNotDuplicated(wine) && this.wineHasDuplicatedWines(wine)) {
				this.showAlertInWineRow(index, this.$t('winelist.addWineModal.duplicateWineDeleteError'))
				return
			}
			this.selectedWineList.splice(index, 1)
      this.validate()
    },
		wineHasDuplicatedWines(wine) {
			const duplicatedWines = this.selectedWineList.filter(w => w.id === wine.id && w.isDuplicate === true)
			return duplicatedWines.length > 0
		},
		wineIsNotDuplicated(wine) {
			return Object.hasOwnProperty.call(wine, 'isDuplicate') === false
		},
    handleDuplicate(wine) {
      const duplicatedWine = { ...wine }
			duplicatedWine.isDuplicate = true;
      const nextLargerBottlesizeId = this.getNextBottleSize(wine.selectedBottlesize.id, wine.selectedBottlesize.attributes.amount)
      duplicatedWine.selectedBottlesize = this.getBottleSizes.find(size => size.id === nextLargerBottlesizeId);
			this.insertDuplicateWine(wine, duplicatedWine)
			this.validate()
    },
		insertDuplicateWine(wine, duplicateWine) {
			const index = this.selectedWineList.indexOf(wine);
			this.selectedWineList.splice(index + 1, 0, duplicateWine);
		},
    handleRename(wine, index) {
      wine.renameMode = true;
      this.updateValues(index)
    },
    handleRenameCancel(wine, index) {
      wine.renameMode = false
      this.updateValues(index)
    },
    handleRenameText(wine, index, newName) {
      wine.title = newName
      wine.renameMode = false
      this.updateValues(index)
    },
    getNextBottleSize(sizeId, amount) {
			const bottleSizes = this.getBottleSizes;
      const curatedBottleSizes = [
				0.75,
				1.5,
				3,
				6,
				9,
				12
			]
			const indexOfCurrentAmount = curatedBottleSizes.indexOf(amount)
			var indexOfNextAmount = 0
			if(indexOfCurrentAmount < curatedBottleSizes.length - 1 && indexOfCurrentAmount >= 0) {
				indexOfNextAmount = indexOfCurrentAmount + 1
			}
			const valueOfNextAmount = curatedBottleSizes[indexOfNextAmount]
			const nextBottleSize = bottleSizes.find(size => size.attributes.amount === valueOfNextAmount)
      return nextBottleSize.id
    },
    updateValues(index) {
      this.$set(this.selectedWineList, index, this.selectedWineList[index])
    },
    yearDropdownText(year) {
      if (year === -1) {
        return this.$t("general.year")
      }
      if (year === 0) {
        return this.$t("general.notDefined")
      }

      return year + ''
      
    },
    selectedBottlesizeText(selectedBottleSize) {
      if (selectedBottleSize && selectedBottleSize.attributes) {
        return selectedBottleSize.attributes.amount + ' ' + this.$t('general.liter_short')
      } else {
        return this.$t('general.size')
      }
    },
    wineSupplierText(wineSupplierId) {
      if (wineSupplierId == null) {
        return ''
      }
      const generalText = this.$t('general.dropdownText')
      return Utils.getWineSupplierText(generalText, wineSupplierId, this.venueSupplierOptions)
    },
		/**
		 * Validate the added wines and emit the validation state
		 * triggered by the OK Button in parent component
		 */
		async validate() {
			this.loading = true
			this.resetAlerts()
			this.resetValidationErrors()

			let foundErrors = 0

			if (this.selectedWineList.length === 0) {
				this.loading = false
				return foundErrors++
      }

			foundErrors += await this.validateSelectedWineList()
			const isValid = foundErrors === 0
			this.loading = false

			return isValid
    },
		async validateSelectedWineList() {
			let foundErrors = 0
			let remoteInvalidDuplicateIndexes = []
			let localInvalidDuplicateIndexes = []

			for await (let [index, wine] of this.selectedWineList.entries()) {
				let isYearZero = wine.selectedYear === -1;
				let isBottleSizeUndefined = wine.selectedBottlesize.id === undefined;
				let isWineSupplierZero = wine.selectedWineSupplier.id == 0;
				let isWineSupplierNull = wine.selectedWineSupplier.id == null;
				let isWineAmountNaN = isNaN(Number(wine.wineAmountFloat));
				let isWineAmountNegative = Number(wine.wineAmountFloat) < 0;
				let isEkNaN = isNaN(Number(wine.ek)) || wine.ek === '';
				let isEkNegative = Number(wine.ek) < 0;
				let isVkNaN = isNaN(Number(wine.vk)) || wine.vk === '';
				let isVkNegative = Number(wine.vk) < 0;

				const validationResult = {
					showYearError: isYearZero,
					showBottleSizeError: isBottleSizeUndefined,
					showWineSupplierError: isWineSupplierZero || isWineSupplierNull,
					showWineAmountError: isWineAmountNaN || isWineAmountNegative,
					showEkError: isEkNaN || isEkNegative,
					showVkError: isVkNaN || isVkNegative,
				}

				if (validationResult.showYearError === true || 
					validationResult.showBottleSizeError === true || 
					validationResult.showWineSupplierError === true || 
					validationResult.showWineAmountError === true || 
					validationResult.showEkError === true || 
					validationResult.showVkError === true) {
						foundErrors++
						this.showAlertInWineRow(index, this.$t('errors.dataNotValid'))
						this.showValidationInWineRow(index, validationResult)
				}

				const remoteInvalidDuplicateIndex = await this.checkForInvalidDuplicateVenueWine(wine, index)
				if (remoteInvalidDuplicateIndex !== null) {
					remoteInvalidDuplicateIndexes.push(remoteInvalidDuplicateIndex)
				}
			}

			localInvalidDuplicateIndexes = this.checkForInvalidDuplicatesLocal()

			if (remoteInvalidDuplicateIndexes.length > 0 || localInvalidDuplicateIndexes.length > 0) {
        foundErrors++
      }

			this.showInvalidDuplicatesInRows(localInvalidDuplicateIndexes, remoteInvalidDuplicateIndexes)

			return foundErrors
    },
		checkForInvalidDuplicatesLocal() {
      let rowIndexesWithLocalDuplicates = []
      this.selectedWineList.forEach((wineA, i) => {
        this.selectedWineList.forEach((wineB, j) => {
          if (i !== j && wineA.title.trim() === wineB.title.trim() && wineA.selectedYear === wineB.selectedYear && wineA.selectedBottlesize.id === wineB.selectedBottlesize.id) {
            rowIndexesWithLocalDuplicates.push(j)
          }
        })
      })
			return rowIndexesWithLocalDuplicates
    },
		/**
		 * Check with the api if the wine is already in the venue	
		 * @param {*} wine 
		 * @param {*} index 
		 */
		async checkForInvalidDuplicateVenueWine(wine, index) {
			if (wine == null) {
				return null
			}
			if (wine.id == null || wine.selectedYear == -1 || wine.selectedBottlesize.id == null) {
				return null
			}

      const response = await DataService.findVenueWine(wine.id, wine.selectedYear, wine.selectedBottlesize.id)
			if (response['data'].data.length > 0) {
        return index
      }
			return null
    },
		showInvalidDuplicatesInRows(localDuplicates, remoteDuplicates) {
			for (const rowIndex of localDuplicates) {
				this.showAlertInWineRow(rowIndex, this.$t('winelist.addWineModal.duplicateLocalWinesHint'))
			}
			for (const rowIndex of remoteDuplicates) {
				this.showAlertInWineRow(rowIndex, this.$t('winelist.addWineModal.duplicateRemoteWinesHint'))
			}
		},
		showAlertInWineRow(index, message) {
			this.selectedWineList[index].alert = {
				alertIsVisible: true,
				alertMessage: message
			}
			this.updateValues(index)
		},
		showValidationInWineRow(index, validationResult) {
			this.selectedWineList[index].validation = {
				showYearError: validationResult.showYearError,
				showBottleSizeError: validationResult.showBottleSizeError,
				showWineSupplierError: validationResult.showWineSupplierError,
				showWineAmountError: validationResult.showWineAmountError,
				showEkError: validationResult.showEkError,
				showVkError: validationResult.showVkError
			}
			this.updateValues(index)
		},
		resetAlerts() {
			for (const wine of this.selectedWineList) {
				wine.alert = {
					alertIsVisible: false,
					alertMessage: ''
				}
			}
		},
		resetValidationErrors() {
			for (const wine of this.selectedWineList) {
				wine.validation = {}
			}
		},
  },
	watch: {
		selectedWineList: {
			handler: function (newVal) {
				this.$emit('onComplementedWines', newVal)
			},
			deep: true
		}
	},
  computed: {
    getCurrency() {
      return Utils.getVenueCurrency(this.getVenue);
    },
    ...mapGetters(['getVenue', 'getBottleSizes'])
  }
}

</script>
<style scoped>
.wine-head span {
  padding: 0;
}

.w-1 {
  width: 28px !important
}

.w-2 {
  width: 100px !important;
}

.w-3 {
  width: 256px !important;
}

@media (max-width: 1080px) {

  .w-3 {
    width: 240px !important;
  }

  .spacer.w-1 {
    display: none;
  }
}
</style>