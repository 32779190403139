<template>
	<div>
		<ComplementWines ref="complementWines" :selectedWinery="selectedWinery" :selectedWines="selectedWines" 
		@onComplementedWines="handleComplementedWines" />
		
		<div class="step-footer">
			<b-button class="back" @click="backClick()">
				{{ $t('general.buttons.back') }}
			</b-button>
			<b-button variant="success" @click="okClick()">
				{{ this.$tc('winelist.addWineModal.complementWine', this.selectedWineList.length) }}
			</b-button>
		</div>
	</div>

</template>

<script>
import ComplementWines from '@/components/modals/addWine/step3/ComplementWines.vue'

export default {
	name: 'Step3',
	props: {
		selectedWines: {
			type: Array,
			required: true
		},
		selectedWinery: {
			type: Object,
			required: true
		}
	},
	created() {
	},
	data() {
		return {
			selectedWineList: []
		}
	},
	components: {
		ComplementWines
	},
	methods: {
		/**
		 * Trigger a validation of the complemented wines and if valid, emit the ok event
		 * to go to the next step
		 */
		async okClick() {
			const winesAreValid = await this.$refs.complementWines.validate()

			if (winesAreValid === true && this.selectedWineList.length > 0) {
				this.$emit('okFromStep3', this.selectedWineList)
			}
		},
		backClick() {
			this.$emit('backFromStep3', this.selectedWineList)
		},
		handleComplementedWines(complementedWines) {
			this.selectedWineList = complementedWines
		}
	}
}

</script>