<template>
	<div>
		<ChooseWineSearch />
		<div class="spacer m"></div>
		<ChooseWine @onSelectedWines="handleSelectedWines" :selectedWinery="selectedWinery" :selectedWines="selectedWines" />

		<b-alert :variant="alertType" :show="alertIsVisible">
			{{ alertMessage }}
		</b-alert>

		<div class="step-footer">
			<b-button class="back" @click="backClick()">
				{{ $t('general.buttons.back') }}
			</b-button>
			<b-button :disabled="continueNotPossible" variant="success" @click="okClick()">
				{{ this.$tc('winelist.addWineModal.chooseWine', selectedWinesWithoutDuplicatesCount) }}
			</b-button>
		</div>
	</div>

</template>

<script>

import ChooseWine from '@/components/modals/addWine/step2/ChooseWine.vue'
import ChooseWineSearch from '@/components/modals/addWine/step2/ChooseWineSearch.vue'

export default {
	name: 'Step2',
	props: {
		selectedWinery: {
			type: Object,
			required: true
		},
		selectedWines: {
			type: Array,
			required: true
		},
	},
	created() {
		this.selectedWineList = this.selectedWines
	},
	mounted() {
    this.$root.$on('showCreateWinesStep2Alert', (alert) => {
      this.handleShowAlert(alert)
    })
		this.$root.$on('hideCreateWinesStep2Alert', () => {
			this.handleHideAlert()
		})
  },
	data() {
		return {
			continueNotPossible: true,
			selectedWineList: [],
			alertType: 'success',
			alertIsVisible: false,
			alertMessage: ''
		}
	},
	components: {
		ChooseWine,
		ChooseWineSearch
	},
	methods: {
		backClick() {
			this.resetAlert()
			this.$emit('backFromStep2')
		},
		handleSelectedWines(selectedWines) {
			this.selectedWineList = selectedWines
		},
		okClick() {
			this.$emit('okFromStep2', this.selectedWineList)
		},
		handleShowAlert(alert) {
			this.alertMessage = alert.message
			this.alertType = alert.type
			this.alertIsVisible = true
		},
		handleHideAlert() {
			this.resetAlert()
		},
		resetAlert() {
			this.alertMessage = ''
			this.alertType = 'success'
			this.alertIsVisible = false
		},
	},
	computed: {
		selectedWinesWithoutDuplicatesCount() {
			const items = this.selectedWineList.filter((wine) =>
				wine.isSelected === true && Object.hasOwnProperty.call(wine, 'isDuplicate') === false
			)
			return items.length
		}
	},
	watch: {
    selectedWineList: {
			handler() {
				this.continueNotPossible = !(this.selectedWineList.length > 0)
			},
			immidiate: true
		}
  },
}

</script>