<template>
	<div class="rename-wine-row">
		<div>
			<p>{{ $t('winelist.addWineModal.wineName') }}</p>
			<b-row>
				<b-col style="flex-grow: 2">
					<b-form-input class="wine-name" ref="wineName" v-model="wineName" :placeholder="currentTitle"></b-form-input>
				</b-col>
				<b-col  class="col-px-width-24">
					<button @click="cancelRename()" class="cancel-button inline-row-button"></button>
				</b-col>
				<b-col  class="col-px-width-24">
					<button @click="renameWine()" class="inlineRow accept"></button>
				</b-col>

			</b-row>
		</div>

	</div>
</template>

<script>


export default {
	name: 'RenameWine',
	data() {
		return {
			wineName: ''
		}
	},
	mounted() {
	},
	props: {
		currentTitle: {
			type: String,
			required: true
		}
	},
	methods: {
		renameWine() {
			this.$emit('renameWine', this.wineName)
		},
		cancelRename() {
			this.$emit('cancelRename')
		},
	}
}
</script>
