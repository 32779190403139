<template>
  <div class="modalView">
    <b-row>
      <b-col cols="6">
        <div class="search-winery">
          <b-form-input class="search-input" ref="newWine" :placeholder="$t('winelist.addWineModal.placeholderWinery')"
            v-model="searchWineryText" v-on:keyup.enter="loadWineries"></b-form-input>
        </div>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import DataService from '@/services/StrapiService'
import DataMappingService from '@/services/DataMappingService'


export default {
  name: 'SearchWinery',
  data() {
    return {
      searchWineryText: '',
			hasTypedEnoughSearchText: false,
			loading: false
    }
  },
	emits: ['foundWineries', 'hasTypedSearchText'],
  watch: {
		searchWineryText: function (val) {
			this.hasTypedEnoughSearchText = val.length > 2;
			this.$emit('hasTypedSearchText', this.hasTypedEnoughSearchText);
		}
	},
  methods: {

    /**
     * Search for Wineries with title and show only 
     * non-venue specific 'master-results' with venue => null
     * 
     * Filter winery's wines alse for 'master-results' with venue => null
     */
    loadWineries() {
      this.loading = true

      DataService.searchWinery(this.searchWineryText.trim()).then((response) => {
        const wineries = DataMappingService.mapSearchWineriesResponse(response)
        this.$emit('foundWineries', wineries);
        this.searchWineryText = ''
        this.loading = false
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },

  }
}
</script>

<style scoped></style>
